import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import StarRating from "../atom/StarRating";
import {calculateHoursSinceMessage, getUserTimeZone} from "../utils";
import AccountStatus from "../atom/AccountStatus";
import AccountPhoneStatus from "../atom/AccountPhoneStatus";


const ClientDetails = ({user}) => {
    const [localTime, setLocalTime] = useState('');
    useEffect(() => {
        const now = new Date();
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: user.timezone || 'Asia/Manila', // Default to Manila if user.timezone is not set
            hour12: true,
        };
        const timeFormatter = new Intl.DateTimeFormat([], options);
        setLocalTime(timeFormatter.format(now));
    }, [user.timezone]);
    return (
        <div>
            <div className="text-lg font-semibold pb-4"> About the Client</div>
            <div className="pb-2">
                {user.email}
            </div>
            <div className="pb-2">

                <AccountStatus verified={user.payment_verified}/>
            </div>
            <div className="pb-2">
                <AccountPhoneStatus verified={user.phone_verified}/>
            </div>
            <div className="pb-2">
                <StarRating totalStars={5} initialRating={user.rating}/>
                <div className="text-sm text-gray-500">
                    {user.review} of {user.review_total} reviews
                </div>
            </div>
            <div className="pb-2">
                <div>{100} contract posted</div>
                <div className="text-sm text-gray-500">51% award, 34 open contract</div>
            </div>
            <div className="pb-2">
                <div className="capitalize">{user.location}</div>
                <div className="text-sm text-gray-500">{localTime}</div>
            </div>


            <div>Member since {calculateHoursSinceMessage(user.created_at, getUserTimeZone('PH'))}</div>
        </div>
    );
};


export default ClientDetails;
