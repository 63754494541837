import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import HomeGridRow from "../components/HomeGridRow";
import {getData} from "../api/crud";
import {getUserDetails} from "../localStorageUtils";
import Tabs from "../components/Tabs";

const HomePage = () => {
    const [forms, setForms] = useState([]);
    const userDetails = getUserDetails();
    const [activeTab, setActiveTab] = useState('mostRecent');

    useEffect(() => {
        const fetchData = async () => {
            let response;
            if (activeTab === 'mostRecent') {
                response = await getData('/contracts');
            } else if (activeTab === 'myContract') {
                response = await getData(`/contracts/user`);
            }
             else if (activeTab === 'myProposal') {
                response = await getData(`/proposals`);
            }


            if (response) {
                setForms(response.data);
                console.log('Forms fetched:', response.data);
            } else {
                console.error('Failed to fetch forms.');
            }
        };

        fetchData();
    }, [activeTab, userDetails.id]);
    return (
        <div className="m-auto max-w-6xl  p-4 rounded mt-2">
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} role={userDetails.role}/>
            {userDetails.role === 'client' && (
                <Link to='/contract/new'>
                    <button className="bg-green-500 text-white px-4 py-2 rounded mt-4">
                        Create New Contract
                    </button>
                </Link>
            )}
            <div className=" py-4 bg-white p-4 rounded mt-2">
                {forms.length > 0 ? (
                    forms.map((form, index) => (
                        <HomeGridRow key={index} data={form}/>
                    ))
                ) : (
                    <div className="bg-gray-50 h-32 flex justify-center items-center text-center">
                        <p>No contract available</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HomePage;
