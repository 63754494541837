import React, { useState, useEffect } from 'react';
import { deleteData } from '../../api/crud';
import config from '../../config'; // Import the config.js file

const FileList = ({ files, isUpdate,directory ='contract' }) => {
    const [fileList, setFileList] = useState([]);

    // Update the fileList state when the files prop changes
    useEffect(() => {
        setFileList(files);
    }, [files]);



    const handleDelete = async (id) => {
        // Call the onDelete function with the file id
        const response = await deleteData(`/${directory}/file/${id}`);

        if (response) {
            console.log('Data deleted successfully:', response);
            // Remove the deleted file from the file list
            const updatedList = fileList.filter(file => file.id !== id);
            setFileList(updatedList);
        } else {
            console.error('Failed to delete data');
        }
    };

    return (
        <>
            {fileList.length > 0 && (
                <div className="mx-auto ">
                    <h2 className=" font-semibold text-gray-800 mb-2">Files</h2>
                    <div className="flex flex-wrap  overflow-x-auto">
                        {fileList.map(file => (
                            <div key={file.id} className="flex w-fit px-2 bg py-1 border rounded bg-gray-50 mb-2 mr-2">
                                <a
                                    href={`${config.backendUrl}/${directory}/file/${file.filename}`}
                                    download={file.original_file_name}
                                    className="text-blue-600 font-semibold hover:underline mr-2"
                                >
                                    {file.original_file_name}
                                </a>
                                {isUpdate && (
                                    <button
                                        onClick={() => handleDelete(file.id)}
                                        className="text-red-600 font-semibold hover:underline"
                                    >
                                        Delete
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default FileList;
