import React, {useEffect, useRef, useState} from "react";
import ClientDetails from "./contract/ClientDetails";
import FileList from "./atom/FileList";

const ProposalsView = ({contract}) => {
    const textareaRef = useRef(null);

    const [tableData, setTableData] = useState(
        contract.tables.map((table) => {
            // Without adding an empty row
            const newData = table.data && table.data.length > 0
                ? table.data.map(row => [...row]) // Just copy the existing data
                : [Array(table.header.length).fill("")]; // Still initialize with a single row if there's no data
            return {data: newData};
        })
    );


    // State to hold header values
    const [headerValues, setHeaderValues] = useState(
        contract.tables.map((table) => table.header)
    );
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset the height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set it to the scroll height
        }
    }, [contract.description]);

    return (
        <div className=" border p-4 bg-white ">
            <form id="createForm">
                {contract.notes && (
                    <div
                        className="w-full my-4">
                        {contract.notes}
                    </div>
                )}
                {contract.tables.map((table, tableIndex) => (
                    <div key={tableIndex} className="mb-6">
                        <div className="flex space-x-4 items-center mb-1">
                            <h3 className="text-gray-700 capitalize">{table.name}</h3>
                        </div>
                        <table className="table-auto">
                            <thead>
                            <tr>
                                {table.header.map((header, headerIndex) => (
                                    <th key={headerIndex} className="border bg-gray-100 px-4 py-2 relative font-normal">
                                        <div className="flex items-center">
                                            {headerValues[tableIndex][headerIndex]}
                                        </div>
                                    </th>
                                ))}
                                <th className="border bg-gray-100 px-4 py-2 relative font-normal">
                                    <div className="flex items-center">
                                        cost
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData[tableIndex].data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                        <td key={colIndex} className="border px-4 py-2">
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}

                <FileList files={contract.files} directory={'proposal'}></FileList>
            </form>

        </div>
    );
};

export default ProposalsView;
