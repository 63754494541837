import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const AccountStatus = ({ verified }) => {
    return (
        <div className="mr-4">
            {verified ? (
                <div >
                    <FontAwesomeIcon icon={verified ? faCheckCircle : faTimesCircle} className={`mr-1 text-green-500`} />
                    Account verified
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon icon={faTimesCircle} className={`mr-1 text-gray-500`} />
                    Unverified account
                </div>
            )}
        </div>
    );
};

export default AccountStatus;
