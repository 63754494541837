import React from 'react';
const WelcomeContent = () => {

    return (
             <div className="z-10">
                        <div className="text-6xl font-bold leading-none text-gray-100 ng-tns-c2176845880-1">
                            <div className="">Welcome to</div>
                            <div className="">FO Procurement</div>
                        </div>
                        <div className="description">
                            <p className="mt-4 text-gray-200">
                                Streamline your procurement process with FO Procurement, the app designed to help you
                                acquire quotations faster and more efficiently than ever before.
                            </p>
                            <p className="mt-2 text-gray-200">
                                Whether you're sourcing materials, services, or products, our platform empowers you to
                                manage your procurement workflow with ease, ensuring timely and cost-effective
                                acquisition.
                            </p>
                        </div>
                    </div>

    );
};

export default WelcomeContent;
