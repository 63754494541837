import axios from 'axios';
import config from '../config';

const getDomain = (domainKey) => {
    const { backendUrl, SSO_URL } = config;

    switch (domainKey) {
        case 'backend':
            console.log("backend 'backend':", backendUrl);
            return backendUrl;
        case 'sso':
            console.log("case 'sso':", SSO_URL);
                        console.log("case 'sso':", backendUrl);
            return SSO_URL;
        default:
            console.log("default:", backendUrl);
            return backendUrl;
    }
};


export const getData = async (endpoint, domainKey) => {
    const domain = getDomain(domainKey);
    console.log(domain)
    try {
        const auth = localStorage.getItem('authToken');
        const response = await axios.get(`${domain}${endpoint}`, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });
        return response;
    } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
        return null;
    }
};

export const postData = async (endpoint, payload, domainKey) => {
    const domain = getDomain(domainKey);
    console.log(domain)
     console.log(domainKey)
    try {
        const auth = localStorage.getItem('authToken');
        const response = await axios.post(`${domain}${endpoint}`, payload, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });
        return response;
    } catch (error) {
        console.error(`Error posting data to ${endpoint}:`, error);
        return error.response; // Return the response from the error object
    }
};


export const putData = async (endpoint, payload, domainKey) => {
    const domain = getDomain(domainKey);
    try {
        const auth = localStorage.getItem('authToken');
        const response = await axios.put(`${domain}${endpoint}`, payload, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });
        return response;
    } catch (error) {
        console.error(`Error putting data to ${endpoint}:`, error);
        return null;
    }
};


export const postFiles = async (files, id, endPoint, domainKey) => {
    const domain = getDomain(domainKey);
    const formData = new FormData();

    // Append files to FormData
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
    }

    // Append contract ID
    if (endPoint === '/contract') {
        formData.append('contract_id', id);
    } else {
        formData.append('proposal_id', id);
    }

    try {
        const response = await axios.post(`${domain}${endPoint}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log('Files uploaded successfully:', response);
        // Handle successful file upload
    } catch (error) {
        console.error('Error uploading files:', error);
        // Handle error
    }
};

export const deleteData = async (endpoint, domain) => {
    try {
        const auth = localStorage.getItem('authToken');
        const response = await axios.delete(`${domain}${endpoint}`, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });
        return response;
    } catch (error) {
        console.error(`Error deleting data from ${endpoint}:`, error);
        return null;
    }
};