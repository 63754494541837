import React, {useEffect, useState} from 'react';
import ContractCreate from "../components/ContractCreate";
import {getData} from "../api/crud";
import {useParams} from "react-router-dom";
import ContractUpdate from "../components/ContractUpdate";

const ContractUpdatePage = () => {
    const [contract, setContract] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {contractId} = useParams();
    const tables = [];
    useEffect(() => {
        if (contractId) {
            console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
            getData(`/contract/${contractId}`).then(response => {
                if (response) {
                    setContract(response.data);
                    setIsLoading(false);
                    console.log('Contract fetched:', response.data.data_draft);
                } else {
                    // Handle error or show a message to the user
                    console.error('Failed to fetch contract.');
                    setIsLoading(false);
                }
            });
        } else {
            setIsLoading(false);
        }
    }, [contractId]);


    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                contractId ? (
                    <ContractUpdate contract={contract}/>
                ) : (
                    <ContractCreate tables={tables}/>
                )
            )}
        </div>
    );


};


export default ContractUpdatePage;
