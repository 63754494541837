// StarRating.js
import React, { useState } from 'react';

const StarRating = ({ totalStars, initialRating }) => {
  const [rating, setRating] = useState(initialRating);

  const handleClick = (value) => {
    setRating(value);
  };

  return (
    <div className="flex items-center">
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span
            key={index}
            className={`text-2xl cursor-pointer ${starValue <= rating ? 'text-yellow-500' : 'text-gray-300'}`}
            onClick={() => handleClick(starValue)}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
