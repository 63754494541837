import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import ContractViewForm from "../components/contract/ContractViewForm";
import {getData} from "../api/crud";
import ClientDetails from "../components/contract/ClientDetails";
import SuccessButton from "../components/atom/SuccessButton";
import ContractView from "../components/ContractView";
import ContractUpdate from "../components/ContractUpdate";
import ContractCreate from "../components/ContractCreate";
import {getUserDetails} from "../localStorageUtils";
import ProposalsView from "../components/ProposalsView";
import SupplierDetails from "../components/SupplierDetails";


const FormPage = () => {
    const [contract, setContract] = useState([]);
    const [proposals, setProposals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isProposalLoading, setIsProposalLoading] = useState(true);
    const {contractId} = useParams();
    const userDetails = getUserDetails();
    console.log("ASDAD");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const contractResponse = await getData(`/contract/${contractId}`);
                if (contractResponse) {
                    setContract(contractResponse.data);
                    setIsLoading(false);
                    console.log('Contract fetched:', contractResponse.data);
                } else {
                    setIsProposalLoading(false);
                    console.error('Failed to fetch contract.');
                }
                    console.log("proposalResponse")
                    const proposalResponse = await getData(`/proposal/contract/${contractId}`);
                    console.log("@@@@@@@@@@@@@@@@@@")
                    console.log(proposalResponse)
                    console.log("@@@@@@@@@@@@@@@@@@")
                    console.log("getData for proposal");

                    if (proposalResponse) {
                        setProposals(proposalResponse.data);
                        setIsProposalLoading(false);
                        console.log('Proposal fetched:', proposalResponse.data);
                    } else {
                         setIsProposalLoading(false);
                        console.error('Failed to fetch proposals.');
                    }
            } catch (error) {
                setIsProposalLoading(false);
                console.error('An error occurred while fetching data:', error);
            }
        };

        fetchData();
    }, [contractId, userDetails.role]);

    return (
        <div className="grid ">
            <div className="grid grid-cols-12 ">
                <div className="col-span-9 ">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            <ContractView contract={contract}/>
                        </>
                    )}
                </div>
                <div className="col-span-3 p-4">
                    {userDetails.role === 'supplier' && (
                        <div className="justify-center my-4">
                            {!contract.submitted ? (
                                  <Link to={`/proposal/contract/${contractId}`}>
                                <SuccessButton
                                    label="Submit Proposal"
                                />
                                 </Link>
                            ) : (
                                <div>
                                    <button
                                        className="px-4 py-2 bg-gray-500 text-white rounded "
                                        disabled={true}
                                    >
                                        Proposal has been submitted.
                                    </button>
                                </div>
                            )}

                        </div>
                    )}

                    {contract.user && (
                        <ClientDetails user={contract.user}/>
                    )}
                </div>

            </div>

                <div>
                     {proposals.length > 0 && <div className="grid grid-cols-12 bg gap-4 p-4 mt-2 font-semibold">Proposal ({proposals.length})</div>}
                    {isProposalLoading ? (
                        <div>Loading...</div>
                    ) : (
                        proposals.map((proposal, index) => (
                            <div key={index} className="grid grid-cols-12 bg mb-2 ">
                                <div className="col-span-9 mr-2 bg-white">
                                    <ProposalsView contract={proposal}/>
                                </div>
                                <div className="col-span-3  ">
                                    {proposal.user && (
                                        <SupplierDetails user={proposal.user}/>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>


        </div>

    );
};


export default FormPage;
