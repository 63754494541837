import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faCog, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {saveUserDetails, getUserDetails, clearUserDetails} from '../localStorageUtils';

const UserMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const userDetails = getUserDetails();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Retrieve user's name from localStorage when component mounts
        const storedName = localStorage.getItem('name');
        if (storedName) {
            setName(storedName);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userEmail');
        window.location.href = '/login';  // Redirect to login page
    };

    return (
        <div className="relative">
            <button
                onClick={toggleMenu}
                className="bg-gray-100 text-white rounded-full w-7 h-7 flex items-center justify-center"
            >
                <FontAwesomeIcon icon={faUser} className="text-gray-500"/>
            </button>
            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                    <div className="px-4 py-2 text-sm text-gray-700">
                        Signed in as <br/>
                        <span className="font-semibold">{userDetails.name}</span>
                    </div>
                    <div className="border-t border-gray-200"></div>
                    <div className="py-1">
                        <a href="/profile"
                           className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            <FontAwesomeIcon icon={faUser} className="mr-2"/>
                            Profile
                        </a>
                        <a href="/settings"
                           className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            <FontAwesomeIcon icon={faCog} className="mr-2"/>
                            Settings
                        </a>
                        <a href="#" onClick={handleLogout}
                           className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2"/>
                            Sign out
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserMenu;
