import React, {useEffect, useRef, useState} from "react";

const NewTableModal = ({isOpen, onClose, onSubmit}) => {
    const [tableName, setTableName] = useState("");
    const [numColumns, setNumColumns] = useState(1);
    const [columnNames, setColumnNames] = useState(["Header 1"]);

    const inputRef = useRef(null);

    // Function to reset modal state when opened or closed
    useEffect(() => {
        if (!isOpen) {
            // Reset modal state when closed
            setTableName("");
            setNumColumns(1);
            setColumnNames(["Header 1"]);
        }
    }, [isOpen]);

    const handleAddColumn = () => {
        setNumColumns(numColumns + 1);
        setColumnNames([...columnNames, `Header ${numColumns + 1}`]); // Add a default header name for the new column
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Filter out columns with empty names
        const filteredColumnNames = columnNames.filter((name) => name.trim() !== "");
        const newTable = {
            name: tableName || `Table ${Math.floor(Math.random() * 1000)}`,
            header: filteredColumnNames,
        };
        onSubmit(newTable);
        onClose();
    };

    const handleColumnNameChange = (index, value) => {
        const newColumnNames = [...columnNames];
        newColumnNames[index] = value;
        setColumnNames(newColumnNames);
    };

    const handleFocus = (index) => {
        if (index === columnNames.length - 1) {
            handleAddColumn();
        }
    };

    return (
        <div className={`fixed inset-0 z-50 ${isOpen ? "block" : "hidden"}`}>
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white rounded-lg w-96 p-8 relative z-10">
                    <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onClose}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                    <h2 className="text-xl font-bold mb-4">Create New Table</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2">Table Name</label>
                            <input
                                className="border rounded-md px-3 py-2 w-full"
                                type="text"
                                value={tableName}
                                placeholder="Table name"
                                onChange={(e) => setTableName(e.target.value)}
                            />
                        </div>
                        {/*<div className="mb-4">*/}
                        {/*    <label className="block text-gray-700 font-bold mb-2">Column Names</label>*/}
                        {/*    {columnNames.map((columnName, index) => (*/}
                        {/*        <input*/}
                        {/*            key={index}*/}
                        {/*            className="border rounded-md px-3 py-2 w-full mb-2"*/}
                        {/*            type="text"*/}
                        {/*            value={columnName}*/}
                        {/*            placeholder={`Header ${index + 1}`}*/}
                        {/*            onChange={(e) => handleColumnNameChange(index, e.target.value)}*/}
                        {/*            onFocus={() => handleFocus(index)}*/}
                        {/*        />*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        <div className="justify-end text-end space-x-2">
                            <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded-md"
                                    onClick={onClose}>
                                Cancel
                            </button>
                            <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded-md">
                                Create Table
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewTableModal;
