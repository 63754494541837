import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faQuestionCircle,
    faEnvelope,
    faBars,
    faPeopleGroup,
    faFileContract,
    faFileSignature
} from '@fortawesome/free-solid-svg-icons';
import UserMenu from './UserMenu';
import {Link} from "react-router-dom";
import NotificationBell from "./atom/NotificationBell";

function Header() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const isActive = (pathnames) => pathnames.includes(window.location.pathname);

    const connectionIconClasses = isActive(['/home', '/', '/contract','/contract/new'])
        ? "text-2xl text-yellow-500"
        : "text-2xl text-gray-200";

    const proposalClass = isActive(['/proposal'])
        ? "text-2xl text-yellow-500"
        : "text-2xl text-gray-200";

    const messageClass = isActive(['/message'])
        ? "text-2xl text-yellow-500"
        : "text-2xl text-gray-200";

    return (
        <header className="border-b-2 header-bg">
            <div className="container text-white mx-auto flex flex-col md:flex-row items-center justify-between">
                <div className="flex justify-between items-center w-full md:w-auto">
                    <h1 className="text-4xl font-bold p-2">
                        <Link to="/">     Procurement</Link>
                    </h1>
                </div>
                <nav className="hidden md:flex md:space-x-4 mt-4 md:mt-0 w-full  ">
                    <ul className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 items-center justify-end w-full">
                        <div className="flex space-x-4">
                            <li>
                                <a href="/home"
                                   className={`hover:bg-gray-500 p-3 rounded flex items-center space-x-2 ${connectionIconClasses} `}>
                                    <div className="flex items-center space-x-2 ">
                                        <FontAwesomeIcon icon={faFileContract}/>
                                        <div className="py-2">
                                            <span className="font-semibold text-xs">Contract</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            {/*<li>*/}
                            {/*    <a href="/proposal"*/}
                            {/*       className={`hover:bg-gray-500 p-3 rounded flex items-center space-x-2 ${proposalClass} `}>*/}
                            {/*        <div className="flex items-center space-x-2 ">*/}
                            {/*            <FontAwesomeIcon icon={faFileSignature} className=""/>*/}
                            {/*            <div className="py-2">*/}
                            {/*                <span className="font-semibold text-xs">Proposal</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                             <li>
                                <a href="/message"
                                   className={`hover:bg-gray-500 p-3 rounded flex items-center space-x-2 ${messageClass} `}>
                                    <div className="flex items-center space-x-2 ">
                                        <FontAwesomeIcon icon={faEnvelope} className=""/>
                                        <div className="py-2">
                                            <span className="font-semibold text-xs">Message</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/contactus"
                                   className={"hover:bg-gray-500 p-3 rounded flex items-center space-x-2 " + (window.location.pathname === '/contactus' ? "text-yellow-500" : "text-gray-200")}>
                                    <div className="flex items-center space-x-2">
                                        <FontAwesomeIcon icon={faQuestionCircle}
                                                         className={"text-2xl text-gray-500" + (window.location.pathname === '/contactus' ? "text-yellow-500" : "text-gray-200")}/>
                                        <div>
                                            <span className="font-semibold text-xs">Help</span>
                                            <p className={"text-xs " + (window.location.pathname === '/contactus' ? "text-yellow-500" : "text-gray-200 ")}>Take
                                                a Tour</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </div>
                        <li className="flex justify-end items-center">
                            <div className="mr-2"> {/* Add margin between the two components */}
                                <UserMenu/>
                            </div>
                            <div className="mr-6"> {/* Add margin between the two components */}
                                {/*<NotificationBell/>*/}
                            </div>
                        </li>

                    </ul>
                </nav>
            </div>


        </header>
    );
}

export default Header;
