// utils.js
import moment from 'moment-timezone';
import countryTimezones from 'country-timezones';
import {postData} from "../api/crud";

export const calculateHoursSinceMessage = (timestamp, userTimeZone) => {
    const messageTimeLocal = moment.utc(timestamp).tz(userTimeZone); // Convert UTC timestamp to the user's local time zone

    const now = moment(); // Current time in user's local time zone
    const elapsedMilliseconds = now - messageTimeLocal;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);

    if (elapsedSeconds < 60) {
      return "Just now";
    } else if (elapsedMinutes < 60) {
      return `${elapsedMinutes} ${elapsedMinutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (elapsedHours < 24 && now.date() === messageTimeLocal.date()) {
      return `${elapsedHours} ${elapsedHours === 1 ? 'hour' : 'hours'} ago`;
    } else if (elapsedHours < 48 && now.date() - 1 === messageTimeLocal.date()) {
      return "Yesterday";
    } else if (elapsedHours < 24 * 7) {
      return `${Math.floor(elapsedHours / 24)} ${Math.floor(elapsedHours / 24) === 1 ? 'day' : 'days'} ago`;
    } else {
      return messageTimeLocal.format('MMM D, YYYY [at] h:mm A'); // Format to desired display format
    }
};

export const calculateTimeDifference = (timestamp, userTimeZone) => {
    const targetTimeLocal = moment.utc(timestamp).tz(userTimeZone); // Convert UTC timestamp to the user's local time zone
    const now = moment(); // Current time in user's local time zone

    if (targetTimeLocal.isBefore(now)) {
        // Time is in the past, calculate time since the message
        const elapsedMilliseconds = now - targetTimeLocal;
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
        const elapsedMinutes = Math.floor(elapsedSeconds / 60);
        const elapsedHours = Math.floor(elapsedMinutes / 60);

        if (elapsedSeconds < 60) {
            return "Just now";
        } else if (elapsedMinutes < 60) {
            return `${elapsedMinutes} ${elapsedMinutes === 1 ? 'minute' : 'minutes'} ago`;
        } else if (elapsedHours < 24 && now.date() === targetTimeLocal.date()) {
            return `${elapsedHours} ${elapsedHours === 1 ? 'hour' : 'hours'} ago`;
        } else if (elapsedHours < 48 && now.date() - 1 === targetTimeLocal.date()) {
            return "Yesterday";
        } else if (elapsedHours < 24 * 7) {
            return `${Math.floor(elapsedHours / 24)} ${Math.floor(elapsedHours / 24) === 1 ? 'day' : 'days'} ago`;
        } else {
            return targetTimeLocal.format('MMM D, YYYY [at] h:mm A'); // Format to desired display format
        }
    } else {
        // Time is in the future, calculate days until the date
        const duration = moment.duration(targetTimeLocal.diff(now)); // Calculate duration between now and the target time
        const daysLeft = Math.ceil(duration.asDays()); // Calculate days left

        if (daysLeft <= 0) {
            return "The date has already passed";
        } else if (daysLeft === 1) {
            return "1 day";
        } else {
            return `${daysLeft} days`;
        }
    }
};


export function formatDateToYYYYMMDD(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Check if the date is invalid
    if (isNaN(date)) {
        console.error("Invalid date string provided:", dateString);
        return "";
    }

    // Extract the year, month, and day, and pad month and day with leading zeros if necessary
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Return the formatted date string
    return `${year}-${month}-${day}`;
}

export const getUserTimeZone = (countryCode) => {
    const timezones = countryTimezones.getCountryTimezones(countryCode);
    return timezones && timezones[0] ? timezones[0] : 'UTC';
};



