import React from 'react';

const SuccessButton = ({ label, onClick }) => {
    return (
        <button
            onClick={onClick}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
        >
            {label}
        </button>
    );
};

export default SuccessButton;
