import React, {useEffect, useState} from 'react';
import {faMapMarkerAlt, faUserTie} from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const {backendUrl} = config;



const ProfilePage = () => {
    const [userData, setUserData] = useState({
        name: '',
        profile_url: '',
        expertise: '',
        interest: '',
        location: '',
        image_url: ''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUserData((prevData) => ({...prevData, [name]: value}));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('No auth token found');

                const response = await fetch(`${backendUrl}/api/account`, {
                    headers: {Authorization: `Bearer ${authToken}`}
                });
                if (!response.ok) throw new Error('Failed to fetch data');

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');

            const response = await fetch(`${backendUrl}/api/account`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) throw new Error('Failed to save data');

            // Handle success response here
            alert('Profile saved successfully');
        } catch (error) {
            console.error('Error saving data:', error);
            // Handle error here
            alert('Failed to save profile. Please try again later.');
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const authToken = localStorage.getItem('authToken');
        if (!authToken) throw new Error('No auth token found');

        if (file) {
            const formData = new FormData();
            formData.append('image', file);

            try {
                // Upload the file
                const response = await fetch(`${backendUrl}/api/account/profile/image`, {
                    method: 'POST',
                    body: formData,
                    headers: {Authorization: `Bearer ${authToken}`}
                });

                if (!response.ok) {
                    throw new Error('Failed to upload image');
                }

                // Get the URL of the uploaded image from the response
                const {imageUrl} = await response.json();

                // Update profile picture URL
                setUserData(prevData => ({...prevData, image_url: imageUrl}));
            } catch (error) {
                console.error('Error uploading image:', error);
                // Handle error
                alert('Failed to upload image. Please try again later.');
            }
        }
    };


    const handleImageClick = () => {
        document.getElementById('profilePictureInput').click();
    };


    return (
        <div className="pt-10 bg-gray-100">
            <div className="h-20 bg-white flex items-center justify-center">
                <div className="flex max-w-xl w-full mx-auto relative ">
                    <div className="flex items-center w-12 ">
                        <img
                            id="profileImage" // Add an id to the image element
                            src={`uploads/${userData.image_url || "default-profile.png"}`}
                            className="h-24 w-24 rounded-full cursor-pointer absolute top-1/2  transform -translate-x-1/2 -translate-y-1/2"
                            alt="Profile"
                            onClick={handleImageClick}
                        />
                    </div>
                    <div className="flex flex-col ml-4 justify-center">
                        <div>{userData.name} </div>
                        <div>{userData.location}</div>
                    </div>
                    <div
                        className="flex flex-col justify-center h-20 ml-4  p-4"> {/* Add justify-center to vertically center the content */}
                        <div
                            className=" flex items-center border-l-2 h-full pl-4"> {/* Add items-center to vertically center the text within the div */}
                            procurement.facadeoption.com/profile/{userData.profile_url}
                        </div>
                    </div>
                    <input
                        type="file"
                        id="profilePictureInput"
                        style={{display: 'none'}}
                        onChange={handleFileChange}
                    />
                </div>
            </div>
            <div className="mb-5 py-5">
                <div className="flex justify-center bg-gray-100 rounded">
                    <div className="p-8 bg-white shadow-md rounded max-w-lg">
                        <form onSubmit={handleSubmit}>
                            <div className="text-lg font-bold mb-4">About Me</div>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 font-semibold">Display
                                    Name*</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={userData.name}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="profile_url" className="block text-gray-700 font-semibold">Profile
                                    URL</label>
                                <input
                                    type="text"
                                    id="profile_url"
                                    name="profile_url"
                                    value={userData.profile_url}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                                <span className="text-sm text-gray-500">Share this public URL with people you want to connect with</span>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="expertise"
                                       className="block text-gray-700 font-semibold">Expertise</label>
                                <textarea
                                    id="expertise"
                                    name="expertise"
                                    value={userData.expertise}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="interest" className="block text-gray-700 font-semibold">Interest</label>
                                <textarea
                                    id="interest"
                                    name="interest"
                                    value={userData.interest}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="location" className="block text-gray-700 font-semibold">Location</label>
                                <input
                                    type="text"
                                    id="location"
                                    name="location"
                                    value={userData.location}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>
                            <div className="flex items-center justify-end">
                                <button type="submit"
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
