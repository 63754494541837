import React, {useState} from 'react';
import {useNavigate, useParams, useLocation, Link} from "react-router-dom";
import config from '../../config';

const {backendUrl} = config;

const ResetPasswordPage = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState(''); // Add error state
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const reset_token = searchParams.get('token');
    console.log("email,reset_token")
    console.log(email, reset_token)
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setError(''); // Clear previous errors

        setPassword(value);
        if (value.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        if (value !== password) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (password.length < 8 || password !== confirmPassword) {
            return;
        }

        // Perform signup logic here (e.g., send data to backend)
        fetch(`${backendUrl}/api/account/reset-password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email, reset_token, password}),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json();
                    // Store the token in localStorage
                    localStorage.setItem('authToken', data.token);
                    localStorage.setItem('userEmail', data.email);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('userId', data.id);
                    console.log('Backend response:', data);
                    navigate('/');
                } else if (res.status === 400) {
                    const data = await res.json();
                    console.error('Signup failed:', data.detail);
                    setError(data.detail || 'Signup failed. Please try again.');
                } else {
                    console.error('Signup failed:', res.statusText);

                }
            })
            .catch((error) => {
                console.error('Signup error:', error);
            });
    };

    return (
        <div>
            <div
                className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 h-screen bg-white">
                <div
                    className="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card ng-tns-c775625097-1">
                    <div className="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                        <div><Link to="/"><img src="/foprocurement.png" alt="foprocurement Logo"/></Link></div>
                        <div className="mt-4 mb-4">
                            <div className="text-2xl font-bold text-gray-700">Reset Password</div>
                            <div className="text-sm text-gray-500">Enter your new password</div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Create a Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                />
                                {passwordError && <p className="text-red-600 text-sm">{passwordError}</p>}
                            </div>
                            <div className="mb-6">
                                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                    Re-enter Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                                {confirmPasswordError && <p className="text-red-600 text-sm">{confirmPasswordError}</p>}
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Reset
                            </button>
                        </form>
                    </div>
                </div>
                <div
                    className="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l ng-tns-c2176845880-1">
                    <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
                         xmlns="http://www.w3.org/2000/svg"
                         className="absolute inset-0 pointer-events-none ng-tns-c2176845880-1">
                        <g fill="none" stroke="currentColor" strokeWidth="100"
                           className="text-gray-700 opacity-25 ng-tns-c2176845880-1">
                            <circle r="234" cx="196" cy="23" className="ng-tns-c2176845880-1"></circle>
                            <circle r="234" cx="790" cy="491" className="ng-tns-c2176845880-1"></circle>
                        </g>
                    </svg>
                    <svg viewBox="0 0 220 192" width="220" height="192" fill="none"
                         className="absolute -top-16 -right-16 text-gray-700 ng-tns-c2176845880-1">
                        <defs className="ng-tns-c2176845880-1">
                            <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                                     patternUnits="userSpaceOnUse" className="ng-tns-c2176845880-1">
                                <rect x="0" y="0" width="4" height="4" fill="currentColor"
                                      className="ng-tns-c2176845880-1"></rect>
                            </pattern>
                        </defs>
                        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                              className="ng-tns-c2176845880-1"></rect>
                    </svg>
                    <div className="z-10">
                        <div className="text-6xl font-bold leading-none text-gray-100 ng-tns-c2176845880-1">
                            <div>Welcome to</div>
                            <div>Whooks</div>
                        </div>
                        <div className="mt-4 text-gray-400">The app to get to know someone better by asking and
                            answering questions!
                        </div>
                        <div className="flex items-center mt-8 ng-tns-c2176845880-1">
                            <div className="flex flex-0 items-center -space-x-1.5 ng-tns-c2176845880-1">
                                <img src="/assets/images/avatars/female-01.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/female-02.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/male-01.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/male-02.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                            </div>
                            <div className="ml-4 font-medium tracking-tight text-gray-400 ng-tns-c2176845880-1">More
                                than 17k people joined us, it's your turn
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ResetPasswordPage;