import React, {useState, useEffect} from 'react';
import StarRating from "./atom/StarRating";
import {faCheckCircle, faTimesCircle, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {calculateHoursSinceMessage, getUserTimeZone} from './utils';
import AccountStatus from "./atom/AccountStatus"; // Import the utility functions

const HomeGridRow = ({data}) => {
    const verified = true;

    return (
        <Link to={`/contract/${data.id}`} >
            <div className='border-b-2 hover:bg-gray-100 p-2 text-gray-600'>
            <div className="text-xs text-gray-600 pb-1">
                Posted {calculateHoursSinceMessage(data.created_at, getUserTimeZone('PH'))}
            </div>
            <div className="">
                <div className="flex items-center ">
                    <div className=" text-black ">{data.title} ({data.user.email})</div>
                    {data.submitted && (
                        <div
                            className="border-2 border-blue-400 text-blue-400 w-fit px-2  rounded-full ml-2 text-xs">Applied</div>
                    )}
                </div>
                      <div className="flex items-center pb-1 ">
                    <div className="text-xs">Est. Budget: $300, contract will close : June 18, 2024 </div>

                </div>
                <div className="truncate-multiline">
                    {data.description}
                </div>
            </div>

            <div className="pb-2">
                {data.tables.map((table, index) => (
                    <div className="bg-gray-200 text-sm w-fit inline-block mr-2 px-2 py-1 rounded"
                         key={index}>{table.name}</div>
                ))}
            </div>
            <div className="flex items-center pb-2">

                   <div className="mr-5"><AccountStatus verified={data.user.payment_verified} /></div>
                 <div className="mr-8"><StarRating totalStars={5} initialRating={data.user.rating}/></div>
                 <div className="mr-8">0 spent</div>
                <div><FontAwesomeIcon icon={faMapMarkerAlt}/> {data.location}</div>
            </div>
                </div>
        </Link>
    );
};

export default HomeGridRow;
