import {BrowserRouter as Router, Routes, Route, Navigate, useLocation, Outlet} from 'react-router-dom';
import LoginPage from './pages/account/LoginPage';
import SignUpPage from './pages/account/SignUpPage';
import ForgotPasswordPage from './pages/account/ForgotPasswordPage';
import ResetPasswordPage from './pages/account/ResetPasswordPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/account/ProfilePage';
import SettingsPage from './pages/account/SettingsPage';
import Header from './components/Header';
import MessagePage from "./pages/MessagePage";
import ContractViewPage from "./pages/ContractViewPage";
import ContractProposalPage from "./pages/contract/ContractProposalPage";
import ProposalPage from "./pages/contract/ProposalPage";
import ContractCreatePage from "./pages/ContractCreatePage";
import ContractUpdatePage from "./pages/ContractUpdatePage";

const PrivateRoutes = () => {
    const auth = {token: localStorage.getItem('authToken')};
    return auth.token ? <Outlet/> : <Navigate to='/login'/>;
}

const AppContent = () => {
    const location = useLocation();
    const isAuthRoute =
        location.pathname === '/login' ||
        location.pathname === '/signup' ||
        location.pathname === '/forgot-password' ||
        location.pathname === '/reset-password';

    return (
        <div className={!isAuthRoute ? 'm-auto ' : ''}>
            {!isAuthRoute && <Header/>}
            <div className="container m-auto ">
                <Routes>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/home" element={<HomePage/>}/>
                        <Route path="/profile" element={<ProfilePage/>}/>
                        <Route path="/settings" element={<SettingsPage/>}/>
                        <Route path="/contract/new" element={<ContractCreatePage/>}/>
                        <Route path="/contract/:contractId" element={<ContractViewPage/>}/>
                        <Route path="/contract/update/:contractId" element={<ContractUpdatePage/>}/>
                        <Route path="/proposal/contract/:contractId" element={<ContractProposalPage/>}/>
                        <Route path="/proposal/:proposalId" element={<ProposalPage/>}/>
                        <Route path="/message" element={<MessagePage/>}/>
                        <Route path="/message/:roomId" element={<MessagePage/>}/>
                    </Route>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/signup" element={<SignUpPage/>}/>
                    <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
                    <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                </Routes>
            </div>
        </div>
    );
}

function App() {
    return (
        <Router>
            <AppContent/>
        </Router>
    );
}

export default App;
