

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const wsbackendUrl = process.env.REACT_APP_WS_BACKEND_URL;
const SSO_URL = process.env.REACT_APP_SSO_URL;

const config = {
  backendUrl,
  wsbackendUrl,
  SSO_URL,
};

export default config;
