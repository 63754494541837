import React from 'react';

const Tab = ({onClick, active, children}) => {
    const activeClasses = active ? 'border-b-2 border-yellow-500 text-yellow-500' : '';
    return (
        <div
            className={`cursor-pointer  ${activeClasses}`}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

const Tabs = ({activeTab, setActiveTab, role}) => {
    return (
        <div className="flex border-b border-gray-200 space-x-4 font-semibold ">
            <Tab onClick={() => setActiveTab('mostRecent')} active={activeTab === 'mostRecent'}>
                Most Recent
            </Tab>
            {role === 'client' ? (
                <Tab onClick={() => setActiveTab('myContract')} active={activeTab === 'myContract'}>
                    My Contract
                </Tab>
            ) : role === 'supplier' ? (
                <Tab onClick={() => setActiveTab('myProposal')} active={activeTab === 'myProposal'}>
                    My Proposal
                </Tab>
            ) : null}

        </div>
    );
};

export default Tabs;
