import React, {useState} from "react";
import NewTableModal from "./NewTableModal";
import {postData, putData} from "../api/crud";
import {useNavigate, useParams} from "react-router-dom";
import {calculateHoursSinceMessage, calculateTimeDifference, getUserTimeZone, formatDateToYYYYMMDD} from "./utils";
import FileList from "./atom/FileList";

const ContractUpdate = ({contract}) => {
    const [formData, setFormData] = useState(contract);
    console.log("~~~~~~~~~~~~~~~~~~~~~~")
    console.log(contract)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const {contractId} = useParams();

    const [tableData, setTableData] = useState(
        contract.tables.map((table) => ({
            data: table.data && table.data.length > 0
                ? [...table.data.map(row => [...row]), Array(table.header.length).fill("")]
                : [Array(table.header.length).fill("")]
        }))
    );


    // State to hold header values
    const [headerValues, setHeaderValues] = useState(
        contract.tables.map((table) => table.header)
    );
    // Delete column by index
    const handleDeleteColumn = (tableIndex, columnIndex) => {
        const updatedHeaderValues = [...headerValues];
        updatedHeaderValues[tableIndex].splice(columnIndex, 1);
        setHeaderValues(updatedHeaderValues);

        const updatedTableData = [...tableData];
        updatedTableData[tableIndex].data.forEach(row => row.splice(columnIndex, 1));
        setTableData(updatedTableData);
    };
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };


    const handleSubmitNewTable = (newTable) => {
        setTableData((prevTableData) => [
            ...prevTableData,
            {data: Array.from({length: 2}, () => Array(newTable.header.length).fill(""))},
        ]);
        setHeaderValues((prevHeaderValues) => [...prevHeaderValues, newTable.header]);
        contract.tables.push(newTable);
    };

    // Update input value in state
    const handleInputChange = (tableIndex, rowIndex, colIndex, value) => {
        const updatedTableData = [...tableData];
        updatedTableData[tableIndex].data[rowIndex][colIndex] = value;
        setTableData(updatedTableData);

        // If the first cell in a row is filled, add a new row
        if (colIndex === 0 && value.trim() !== "" && rowIndex === updatedTableData[tableIndex].data.length - 1) {
            setTableData((prevTableData) => {
                const newData = [...prevTableData];
                newData[tableIndex].data.push(Array(contract.tables[tableIndex].header.length).fill(""));
                return newData;
            });
        }
    };

    // Update header value in state
    const handleHeaderChange = (tableIndex, headerIndex, value) => {
        const updatedHeaderValues = [...headerValues];
        updatedHeaderValues[tableIndex][headerIndex] = value;
        setHeaderValues(updatedHeaderValues);
    };

    // Delete table by index
    const handleDeleteTable = (tableIndex) => {
        const updatedTables = [...contract.tables];
        updatedTables.splice(tableIndex, 1);
        setTableData(tableData.filter((_, index) => index !== tableIndex));
        setHeaderValues(headerValues.filter((_, index) => index !== tableIndex));
        // Update the tables array
        contract.tables.splice(tableIndex, 1);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Filter out completely empty rows from the table data
        const filteredTableData = tableData.map((table) => ({
            ...table,
            data: table.data.filter((row) => row.some((cell) => cell.trim() !== "")),
        }));

        const formData1 = {
            title: e.target.title.value,
            description: e.target.description.value,
            location: e.target.location.value,
            est_budget: e.target.est_budget.value,
            contract_end: e.target.contract_end.value,
            tables: contract.tables.map((table, tableIndex) => ({
                name: table.name,
                header: headerValues[tableIndex], // Use updated header values
                data: filteredTableData[tableIndex].data,
            }))
        };
        var response = {}

        formData1.id = contract.id
        response = await putData(`/contract`, formData1);
        navigate(`/contract/${contract.id}`);
        // console.log(response);
        // console.log(formData1); // You can send this formData to your backend API
    };

    return (
        <div className="mt-4 border p-4 bg-white  ">

            <form id="createForm" onSubmit={handleSubmit}>
                <div className="mb-4 max-w-lg">
                    <label className="block text-gray-700 text-sm  mb-2">
                        Title
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required={true}
                    />
                </div>
                <div className="mb-4 max-w-4xl">
                    <label className="block text-gray-700 text-sm  mb-2">
                        Description
                    </label>
                    <textarea
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}

                    ></textarea>
                </div>
                <div className="flex flex-wrap  mb-4 bg space-x-4">
                    <div>
                        <label className="block text-gray-700 text-sm mb-2" htmlFor="estBudget">
                            Estimate Budget
                        </label>
                        <input
                            className="max-w-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="est_budget"
                            type="text"
                            name="est_budget"
                            value={formData.est_budget}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm mb-2" htmlFor="contractEnd">
                            Contract End
                        </label>
                        <input
                            className="max-w-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="contract_end"
                            type="date" /* Changed type to 'date' */
                            name="contract_end"
                            required={true}
                            value={formatDateToYYYYMMDD(formData.contract_end)}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm  mb-2">
                        location
                    </label>
                    <input
                        className="max-w-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                    />
                </div>
                {contract.tables.map((table, tableIndex) => (
                    <div key={tableIndex} className="mb-6">
                        <div className="flex space-x-4 items-center mb-2">
                            <h3 className="text-lg ">{table.name}</h3>
                            <button
                                onClick={() => handleDeleteTable(tableIndex)}
                                className="border-2 border-red-500 text-red-500  px-2  rounded"
                            >
                                Remove
                            </button>
                        </div>
                        <table className="table-auto  mt-2">
                            <thead>
                            <tr>
                                {table.header.map((header, headerIndex) => (
                                    <th key={headerIndex} className="border bg-gray-100 px-4 py-2 relative">
                                        <div className="flex items-center">
                                            <input
                                                type="text"
                                                className="border border-gray-300 px-2 py-1 w-full rounded"
                                                value={headerValues[tableIndex][headerIndex]}
                                                onChange={(e) => handleHeaderChange(tableIndex, headerIndex, e.target.value)}
                                            />
                                            <button
                                                className=" text-red-500 px-2  rounded-full"
                                                onClick={() => handleDeleteColumn(tableIndex, headerIndex)}
                                            >
                                                X
                                            </button>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {tableData[tableIndex].data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                        <td key={colIndex} className="border px-4 py-2">
                                            {/* Input field for each cell */}
                                            <input
                                                type="text"
                                                className="border border-gray-300 px-2 py-1 rounded w-full"
                                                placeholder={`Enter data`}
                                                value={cell}
                                                onChange={(e) => handleInputChange(tableIndex, rowIndex, colIndex, e.target.value)}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}

            </form>
            <div className='mb-4'>
                <button onClick={toggleModal} className="bg-blue-500 text-white px-4 py-2 rounded">
                    Add New Table
                </button>
            </div>
            <NewTableModal isOpen={isModalOpen} onClose={toggleModal} onSubmit={handleSubmitNewTable}/>
            <FileList files={contract.files}  isUpdate={true} endPoint={'12'}></FileList>

            <div className="justify-end text-end space-x-2">
                <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded-md">
                    Cancel
                </button>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded-md" form="createForm">
                    Submit
                </button>
            </div>


        </div>
    );
};

export default ContractUpdate;
