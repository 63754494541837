import React, {useState, useEffect, useRef} from 'react';

const ContractViewForm = ({contract}) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        adjustTextareaHeight();
    }, [contract.description]);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset height to auto
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'; // Set height to scrollHeight
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">Contract </h2>
            <form>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {contract.title}
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="title"
                        value={contract.title}
                        disabled={true}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Description
                    </label>
                    <textarea
                        ref={textareaRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="description"
                        value={contract.description}
                        style={{overflow: 'hidden', resize: 'none'}}
                        disabled={true}
                        onChange={adjustTextareaHeight} // Adjust height on change
                    ></textarea>
                </div>

                <div className="">
                    {contract.tables && contract.tables.map((table, tableIndex) => (
                        <div key={tableIndex} className="col-span-12   mb-4">
                            <div className="text-lg  mb-2">{table.name}</div>
                            <table className=" border rounded">
                                <thead>
                                <tr className="bg-gray-100">
                                    {table.data[0].map((header, headerIndex) => (
                                        <th key={headerIndex} className="border px-4 py-2 text-left">{header}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {table.data.slice(1).map((row, rowIndex) => (
                                    <tr key={rowIndex} className={(rowIndex % 2 === 0) ? 'bg-white' : 'bg-gray-50'}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex} className="border px-4 py-2">{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>

            </form>
        </div>
    );
};

export default ContractViewForm;
