import React, {useEffect, useState} from 'react';
import ContractCreate from "../components/ContractCreate";
import {getData} from "../api/crud";
import {useParams} from "react-router-dom";
import ContractUpdate from "../components/ContractUpdate";

const ContractCreatePage = () => {
    const tables = [];
    return (
        <div>
            <ContractCreate tables={tables}/>
        </div>
    );
};


export default ContractCreatePage;
