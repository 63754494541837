import React, {useEffect, useState} from 'react';
import config from '../../config';
import Alert from "../atom/Alert";
import {putData} from "../../api/crud";

const {backendUrl} = config;

const AccountForm = () => {
    const [alert, setAlert] = useState({show: false, type: '', message: ''});

    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        location: '',
        setting: {role: ''}
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('No auth token found');

                const response = await fetch(`${backendUrl}/account`, {
                    headers: {Authorization: `Bearer ${authToken}`}
                });
                if (!response.ok) throw new Error('Failed to fetch data');

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name === 'role') {
            setUserData((prevData) => ({
                ...prevData,
                setting: {
                    ...prevData.setting,
                    [name]: value
                }
            }));
        } else {
            setUserData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            console.log(userData);
            const response = await putData(`/account`, userData);
            console.log(response);
            setAlert({show: true, type: 'success', message: 'submitted successfully!'});
        } catch (error) {
            setAlert({show: true, type: 'error', message: 'Failed to save profile. Please try again later.'});
        }
    };

    return (
        <div className="relative p-8">
            <Alert
                type={alert.type}
                message={alert.message}
                show={alert.show}
                onClose={() => setAlert({...alert, show: false})}
            />
            <form onSubmit={handleSubmit}>
                <div className="border-b-2 pb-6">
                    <div>
                        <div className="text-2xl font-bold pb-6">Account</div>
                        <div className="text-gray-500">Share your unique Profile URL with people you'd like to connect
                            with.
                        </div>
                    </div>
                    <div className="pt-6 pb-6">
                        <label htmlFor="first_name" className="block text-sm font-semibold font-medium text-gray-700">First
                            Name</label>
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            placeholder="First Name"
                            value={userData.first_name}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <label htmlFor="last_name" className="block text-sm font-semibold font-medium text-gray-700">Last
                            Name</label>
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="Last Name"
                            value={userData.last_name}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <label htmlFor="location"
                               className="block text-sm font-semibold font-medium text-gray-700">Location</label>
                        <input
                            type="text"
                            name="location"
                            id="location"
                            placeholder="Location"
                            value={userData.location}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="w-96">
                        <label htmlFor="role">Register as:</label>
                        <select
                            name="role"
                            id="role"
                            value={userData.setting.role}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="" disabled>Select role</option>
                            <option value="client">Client</option>
                            <option value="supplier">Supplier</option>
                        </select>
                        <div className="text-gray-500 text-sm">Share this with people you want to connect with
                            immediately without them needing approval from you
                        </div>
                    </div>
                </div>
                <div className="flex justify-end space-x-4 mt-4">
                    <button
                        type="button"
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AccountForm;
