import React, {useState} from "react";
import NewTableModal from "./NewTableModal";
import {postData, postFiles} from "../api/crud";
import {useNavigate} from "react-router-dom";
import {formatDateToYYYYMMDD} from "./utils";

const ContractCreate = ({tables}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [selectedFiles, setSelectedFiles] = useState([]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    // State to hold input values
    const [tableData, setTableData] = useState(
        tables.map((table) => ({
            data: Array.from({length: 0}, () => Array(table.header.length).fill("")),
        }))
    );

    // State to hold header values
    const [headerValues, setHeaderValues] = useState(
        tables.map((table) => table.header)
    );

    // Delete column by index
    const handleDeleteColumn = (tableIndex, columnIndex) => {
        const updatedHeaderValues = [...headerValues];
        updatedHeaderValues[tableIndex].splice(columnIndex, 1);
        setHeaderValues(updatedHeaderValues);

        const updatedTableData = [...tableData];
        updatedTableData[tableIndex].data.forEach(row => row.splice(columnIndex, 1));
        setTableData(updatedTableData);
    };

    // Delete table by index
    const handleDeleteTable = (tableIndex) => {
        const updatedTables = [...tables];
        updatedTables.splice(tableIndex, 1);
        setTableData(tableData.filter((_, index) => index !== tableIndex));
        setHeaderValues(headerValues.filter((_, index) => index !== tableIndex));
        // Update the tables array
        tables.splice(tableIndex, 1);
    };

    // Add new column
    const handleAddColumn = (tableIndex) => {
        const newHeaderValues = [...headerValues];
        newHeaderValues[tableIndex].push(`Header ${newHeaderValues[tableIndex].length + 1}`);
        setHeaderValues(newHeaderValues);

        const newTableData = [...tableData];
        newTableData[tableIndex].data = newTableData[tableIndex].data.map(row => [...row, '']);
        setTableData(newTableData);
    };

    // Add new row
    const handleAddRow = (tableIndex) => {
        const newTableData = [...tableData];
        const newRow = new Array(headerValues[tableIndex].length).fill('');
        newTableData[tableIndex].data.push(newRow);
        setTableData(newTableData);
    };

    // Update input value in state
    const handleInputChange = (tableIndex, rowIndex, colIndex, value) => {
        const updatedTableData = [...tableData];
        updatedTableData[tableIndex].data[rowIndex][colIndex] = value;
        setTableData(updatedTableData);

        // If the first cell in a row is filled, add a new row
        if (colIndex === 0 && value.trim() !== "" && rowIndex === updatedTableData[tableIndex].data.length - 1) {
            setTableData((prevTableData) => {
                const newData = [...prevTableData];
                newData[tableIndex].data.push(Array(headerValues[tableIndex].length).fill(""));
                return newData;
            });
        }
    };

    // Update header value in state
    const handleHeaderChange = (tableIndex, headerIndex, value) => {
        const updatedHeaderValues = [...headerValues];
        updatedHeaderValues[tableIndex][headerIndex] = value;
        setHeaderValues(updatedHeaderValues);
    };

    const handleSubmitNewTable = (newTable) => {
        setTableData((prevTableData) => [
            ...prevTableData,
            {data: Array.from({length: 2}, () => Array(newTable.header.length).fill(""))},
        ]);
        setHeaderValues((prevHeaderValues) => [...prevHeaderValues, newTable.header]);
        tables.push(newTable);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Filter out completely empty rows from the table data
        const filteredTableData = tableData.map((table) => ({
            ...table,
            data: table.data.filter((row) => row.some((cell) => cell.trim() !== "")),
        }));

        const formData = {
            title: e.target.title.value,
            description: e.target.description.value,
            est_budget: e.target.est_budget.value,
            location: e.target.location.value,
            contract_end: formatDateToYYYYMMDD(e.target.contract_end.value),
            tables: tables.map((table, tableIndex) => ({
                name: table.name,
                header: headerValues[tableIndex], // Use updated header values
                data: filteredTableData[tableIndex].data,
            })),
        };
        console.log(formData)
        const response = await postData(`/contract`, formData);
        console.log(response)
        postFiles(selectedFiles, response.data.id, '/contract');
        navigate(`/contract/update/${response.data.id}`);
    };

    const handleFileChange = (e) => {
        // Get the selected files
        const selectedFiles = e.target.files;
        // Update the state with the selected files
        setSelectedFiles(selectedFiles);
    };

    return (
        <div className="mt-4 border p-4  bg-white">
            <form id="createForm" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm  mb-2">
                        Title
                    </label>
                    <input
                        className="max-w-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="title"
                        required={true}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm  mb-2">
                        Description
                    </label>
                    <textarea
                        className="max-w-4xl shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="description"
                        rows={5}

                    ></textarea>
                </div>

                <div className="flex flex-wrap  mb-4 bg space-x-4">
                    <div>
                        <label className="block text-gray-700 text-sm mb-2" htmlFor="estBudget">
                            Estimate Budget
                        </label>
                        <input
                            className="max-w-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="est_budget"
                            type="text"
                            name="est_budget"
                            required={true}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm mb-2" htmlFor="contractEnd">
                            Contract End
                        </label>
                        <input
                            className="max-w-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="contract_end"
                            type="date" /* Changed type to 'date' */
                            name="contract_end"
                            required={true}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm  mb-2">
                        location
                    </label>
                    <input
                        className="max-w-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="location"
                        required={true}
                    />
                </div>

                {tables.map((table, tableIndex) => (
                    <div key={tableIndex} className="mb-6">
                        <div className="flex space-x-4 items-center mb-2">
                            <h3 className="text-lg ">{table.name}</h3>
                            <button
                                onClick={() => handleDeleteTable(tableIndex)}
                                className="border-2 border-red-500 text-red-500 px-2 rounded"
                                type="button"
                            >
                                Remove
                            </button>
                        </div>
                        <div className="flex space-x-4 mb-4">
                            <button
                                onClick={() => handleAddColumn(tableIndex)}
                                className="border-2 border-green-500 text-green-500 px-2 rounded"
                                type="button"
                            >
                                Add Column
                            </button>
                            <button
                                onClick={() => handleAddRow(tableIndex)}
                                className="border-2 border-green-500 text-green-500 px-2 rounded"
                                type="button"
                            >
                                Add Row
                            </button>
                        </div>
                        <table className="table-auto mt-2">
                            <thead>
                            <tr>
                                {table.header.map((header, headerIndex) => (
                                    <th key={headerIndex} className="border px-4 py-2 relative bg-gray-100">
                                        <div className="flex items-center">
                                            <input
                                                type="text"
                                                className="border border-gray-300 px-2 py-1 w-full rounded"
                                                value={headerValues[tableIndex][headerIndex]}
                                                onChange={(e) => handleHeaderChange(tableIndex, headerIndex, e.target.value)}
                                            />
                                            <button
                                                className="text-red-500 px-2 rounded-full"
                                                onClick={() => handleDeleteColumn(tableIndex, headerIndex)}
                                                type="button"
                                            >
                                                X
                                            </button>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {tableData[tableIndex].data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                        <td key={colIndex} className="border px-4 py-2">
                                            {/* Input field for each cell */}
                                            <input
                                                type="text"
                                                className="border border-gray-300 px-2 py-1 rounded w-full"
                                                placeholder={`Enter data`}
                                                value={cell}
                                                onChange={(e) => handleInputChange(tableIndex, rowIndex, colIndex, e.target.value)}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}

            </form>

            <input type="file" onChange={handleFileChange} multiple/>
            <div className='mb-4'>
                <button onClick={toggleModal} className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
                    Add New Table
                </button>
            </div>
            <NewTableModal isOpen={isModalOpen} onClose={toggleModal} onSubmit={handleSubmitNewTable}/>

            <div className="justify-end text-end space-x-2">
                <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded-md">
                    Cancel
                </button>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded-md" form="createForm">
                    Submit
                </button>
            </div>


        </div>
    );
};

export default ContractCreate;
