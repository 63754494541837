// localStorageUtils.js


const AUTH_TOKEN = 'authToken';
const USER_EMAIL_KEY = 'userEmail';
const USER_NAME_KEY = 'name';
const USER_ID_KEY = 'userId';
const USER_ROLE_KEY = 'role';

export function saveUserDetails(user) {
    localStorage.setItem(AUTH_TOKEN, user.authToken);
    localStorage.setItem(USER_EMAIL_KEY, user.email);
    localStorage.setItem(USER_NAME_KEY, user.name);
    localStorage.setItem(USER_ID_KEY, user.id);
    localStorage.setItem(USER_ROLE_KEY, user.role);
}

export function getUserDetails() {
    return {
        authToken: localStorage.getItem(AUTH_TOKEN),
        email: localStorage.getItem(USER_EMAIL_KEY),
        name: localStorage.getItem(USER_NAME_KEY),
        id: localStorage.getItem(USER_ID_KEY),
        role: localStorage.getItem(USER_ROLE_KEY),
    };
}

export function clearUserDetails() {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_EMAIL_KEY);
    localStorage.removeItem(USER_NAME_KEY);
    localStorage.removeItem(USER_ID_KEY);
    localStorage.removeItem(USER_ROLE_KEY);
}
