import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import ContractViewForm from "../../components/contract/ContractViewForm";
import {getData} from "../../api/crud";
import ClientDetails from "../../components/contract/ClientDetails";
import SuccessButton from "../../components/atom/SuccessButton";
import ProposalForm from "../../components/ProposalForm";


const FormPage = () => {
    const [contract, setContract] = useState([]);
    const {proposalId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getData(`/proposal/${proposalId}`).then(response => {
            if (response) {
                setContract(response.data);
                setIsLoading(false);
                console.log('Forms fetched:', response.data);
            } else {
                console.error('Failed to fetch forms.');
            }
        });
    }, []);

    return (
        <div className="grid grid-cols-12 gap-4 p-4">
            <div className="col-span-9 p-4">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    contract && Object.keys(contract).length > 0 ? (
                        <ProposalForm contract={contract} isView={true}/>
                    ) : null
                )}
            </div>
            <div className="col-span-3 p-4">
                {contract && contract.user && (
                    <ClientDetails user={contract.user}/>
                )}
            </div>
        </div>
    );

};


export default FormPage;
