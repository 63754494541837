import React, {useEffect, useRef, useState} from "react";
import SuccessButton from "./atom/SuccessButton";
import {postData, postFiles} from "../api/crud";
import {useNavigate} from "react-router-dom";
import FileList from "./atom/FileList";
import {calculateHoursSinceMessage, calculateTimeDifference, getUserTimeZone} from "./utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

const ProposalForm = ({contract, isView}) => {
    const navigate = useNavigate();
    const [note, setNote] = useState('');
    const textareaRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [tableData, setTableData] = useState(
        contract.tables.map((table) => ({
            data: table.data && table.data.length > 0
                ? [...table.data.map(row => [...row, ''])] // Initialize cost column with empty string
                : []
        }))
    );
    const handleFileChange = (e) => {
        // Get the selected files
        const selectedFiles = e.target.files;
        // Update the state with the selected files
        setSelectedFiles(selectedFiles);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            contract_id: contract.id,
            notes: note,
            tables: tableData.map((table, tableIndex) => ({
                data: table.data.map(row => parseFloat(row[row.length - 1] || 0)), // Extract and parse the cost values
                name: contract.tables[tableIndex].name,
            }))
        };
        console.log(formData);
        const response = await postData(`/proposal/contract/${contract.id}`, formData);
        console.log(response)
        postFiles(selectedFiles, response.data.id, '/proposal');
        console.log(selectedFiles)
        navigate(`/contract/${contract.id}`);

        // console.log(data);
    };


    const handleCellDataChange = (e, tableIndex, rowIndex, columnIndex) => {
        const newData = [...tableData];
        newData[tableIndex] = {...newData[tableIndex]}; // Copy the specific table
        newData[tableIndex].data = [...newData[tableIndex].data]; // Copy the data array
        newData[tableIndex].data[rowIndex] = [...newData[tableIndex].data[rowIndex]]; // Copy the specific row
        newData[tableIndex].data[rowIndex][columnIndex] = e.target.value; // Update the specific cell
        setTableData(newData);
    };

    return (
        <div className="mt-4 border p-4 bg-white">
            <form id="createForm" onSubmit={handleSubmit}>
                <div className="mt-5 mb-2 max-w-lg">
                    <label className="block text-gray-700 text-lg font-semibold ">
                        {contract.title}
                    </label>
                    <div className="flex justify-start space-x-4 text-sm text-gray-500">
                        <div>
                            Posted {calculateHoursSinceMessage(contract.created_at, getUserTimeZone('PH'))}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-500"/> {contract.location}
                        </div>
                    </div>
                </div>
                <div className="mb-2 max-w-4xl">
                    <div>{contract.description}</div>
                </div>
                <div className="mb-4 max-w-4xl">
                    <div className="flex justify-start space-x-4 text-sm text-gray-500">
                        <div>
                            Est.Budget : {contract.est_budget}
                        </div>
                        <div>
                            Contract end in {calculateTimeDifference(contract.contract_end, getUserTimeZone('PH'))}
                        </div>
                    </div>
                </div>
                <FileList files={contract.files} isUpdate={isView} endPoint={'12'}></FileList>

                <div className="">
                    <div className="">Proposal</div>
                    <textarea rows={4} className="w-full border"
                              onChange={(e) => setNote(e.target.value)}
                    />
                </div>
                {contract.tables.map((table, tableIndex) => (
                    <div key={tableIndex} className="mb-6">
                        <div className="flex space-x-4 items-center mb-2">
                            <h3 className="text-lg font-bold">{table.name}</h3>
                        </div>
                        <table className="table-auto mt-2">
                            <thead>
                            <tr>
                                {table.header.map((header, headerIndex) => (
                                    <th key={headerIndex} className="border bg-gray-100 px-4 py-2 relative">
                                        <div className="flex items-center">
                                            {header}
                                        </div>
                                    </th>
                                ))}
                                <th className="border bg-gray-100 px-4 py-2 relative">
                                    <div className="flex items-center">
                                        Cost
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData[tableIndex].data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                        <td key={colIndex} className="border px-4 py-2">
                                            {colIndex === row.length - 1 ? (
                                                <input
                                                    className="border"
                                                    type="text"
                                                    value={cell}
                                                    onChange={(e) => handleCellDataChange(e, tableIndex, rowIndex, colIndex)}
                                                />
                                            ) : (
                                                cell
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>
                ))}


            </form>
            <div className="my-b">
                <div>
                <input type="file" onChange={handleFileChange} multiple/>
                </div>

            </div>
            <div className="justify-end text-end space-x-2">
                <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded-md">
                    Cancel
                </button>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded-md" form="createForm">
                    Submit
                </button>
            </div>
        </div>
    );
};

export default ProposalForm;
